.block-links,
.footer-next-text {
    background: #e4f0f1;
    display: table;
    padding: 10px 30px 10px 30px;
    border-radius: 10px;
}

.block-links ul,
.footer-next-text ul {
    padding: 0;
    columns: 1 !important;
}

.block-links li,
.footer-next-text li {
    list-style: none;
    display: inline;
}

.block-links li a,
.footer-next-text li a {
    display: inline-block;
    background: #fff;
    padding: 10px 12px;
    line-height: 1.4em;
    text-decoration: none;
    margin: 6px 8px 6px 0;
    color: #000;
    border-radius: 10px;
    border: 1px dotted #000;
}

.review-block {
    margin-top: 10px;
    display: inline-block;
}

.review-text {
    background: #e4f0f1;
    display: table;
    padding: 10px 15px;
    border-radius: 10px;
}

.author {
    width: 100%;

    &__title {
        font-size: 30px;
        text-align: center;
        margin: 20px 0;
    }

    &__image-wrapper {
        width: 100%;
    }

    &__image {
        width: 100%;
        object-fit: cover;
    }

    &__description {
        margin-top: 20px;
    }

    &__recommendation {
        margin-top: 20px;
    }

    .recommendation {
        &__list {
            list-style: none;
            column-count: 2;
            margin-left: 0;
            padding-left: 0;
            font-size: 14px;
        }

        &__title {
            margin: 20px 0;
        }
    }
}
